import React, { useEffect, useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";

const Withdrawal_History = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [userid, setUserId] = useState("");

  const Withdrawal_History_API = async () => {
    try {
      let responce = await API?.post(
        `/withdrawalReport`,
        {
          uid: user,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${jwt}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce?.data?.data[0];
      console.log("withdrawalReport", responce);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.uid,

          wallet: item.wallet
            ? item.wallet.substring(0, 4) +
              "..." +
              item.wallet.substring(item.wallet.length - 4)
            : null,
          request_date: item?.request_date,
          Request_amount: `$ ${item?.Request_amount}`,
          amountusd:`$ ${ item?.amountusd}`,
          sidamount: `$ ${item?.adminchargerate}`,
          ss: item?.ss,
          paid_date: item?.paid_date,
          amount:item?.amount,
          BNBvalue:item?.BNBvalue,
          tokenvalue:item?.tokenvalue,
          // txn:<a  className="link-light"href={`https://bscscan.com/tx/${item?.txn}`} target="_blank" style={{whiteSpace:'nowrap'}}>View Txn</a>
          TokenTXN:<a  className="link-light"href={`https://bscscan.com/tx/${item?.TokenTXN}`} target="_blank" style={{whiteSpace:'nowrap'}}>View Txn</a>,
          USDTTXN:<a  className="link-light"href={`https://bscscan.com/tx/${item?.USDTTXN}`} target="_blank" style={{whiteSpace:'nowrap'}}>View Txn</a>
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Withdrawal_History_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "Sr.No", accessor: "sr" },
      { Header: "User ID", accessor: "uid" },
      { Header: "Wallet", accessor: "wallet" },
      { Header: "Requested Amount", accessor: "Request_amount" },
      { Header: "Deduction Charge", accessor: "sidamount" },
      { Header: "Withdrawal Amount", accessor: "amountusd" },
      // { Header: "Token", accessor: "amount" },
      // { Header: "Txn", accessor: "txn" },
      { Header: "Token Value(30%)", accessor: "tokenvalue" },
      { Header: "USDT Value(70%)", accessor: "BNBvalue" },
      { Header: "Token Txn", accessor: "TokenTXN" },
      { Header: "USDT Txn", accessor: "USDTTXN" },
      { Header: "Requested Date", accessor: "request_date" },
      { Header: "Status ", accessor: "ss" },
      { Header: "Paid Date", accessor: "paid_date" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  Withdrawal History
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">History</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="#">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Withdrawal History
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 jashja">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Withdrawal_History;
